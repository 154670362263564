import { pageNames } from "Utils/Data";

const getPageMsgLst = () => {
  return {
    [pageNames.HOME]: [
      "The 747 Most Wanted and Loved Daily Raffle has started again 🎉 Everyday starting at 7:47 PM I am giving away 103 CASH prizes.",
    ],
    [pageNames.PAST_DRAWS]: [
      "The 747 Most Wanted and Loved Daily Raffle has started again 🎉 Everyday starting at 7:47 PM I am giving away 103 CASH prizes.",
    ],
    [pageNames.DAILY_ENTRIES]: [
      "The 747 Most Wanted and Loved Daily Raffle has started again 🎉 Everyday starting at 7:47 PM I am giving away 103 CASH prizes.",
    ],
  };
};

const getDefaultRaffleIDLst = () => {
  return [
    { id: "3", name: "Daily Raffle", active: true },
    { id: "11", name: "747 BDAY Raffle", active: false },
  ];
};

export { getPageMsgLst, getDefaultRaffleIDLst };
